<!-- parte inicial -->
<section class="d-flex justify-content-center flex-column text-center container" style="gap:30px;padding: 30px;">

    <div class="image-container">
        <img src="/assets/media/logo.png" class="logo" alt="logotipo">
    </div>

    <h1 class="titulo">Bem-vindo à nossa plataforma de saúde e bem-estar!</h1>

    <span class="conteudo">Tenha acesso a conteúdos exclusivos para desenvolver os três principais pilares da sua
        vida: <b>corpo</b>,
        <b>mente</b> e <b>espírito</b>!
    </span>

    <div class="d-flex justify-content-center botoes-container">
        <a href="https://app.vivaesteio.grupoeasylife.com.br" class="btn btn-outline-primary botao">Entrar</a>
        <a href="/como-acessar" class="btn btn-outline-primary botao">Como Acessar</a>
    </div>

</section>

<section class="secound">
    <div class="items">
        <div class="hero-items">
            <img src="assets/media/corpo-mente-espirito.png" alt="" class="hero-item" data-aos="fade-up">
        </div>

        <div class="row">
            <div class="col-md-6">
                <div>
                    <h1 class="titulo text-white">Lembre-se</h1>

                    <p class="conteudo text-white">Com a nossa plataforma exclusiva você tem acesso a um mundo de
                        conteúdos quando e
                        onde quiser, a apenas um
                        toque de distância.</p>
                </div>
                <div>
                    <h1 class="titulo text-white">Veja como é fácil acessar</h1>

                    <p class="conteudo text-white">Clique no botão abaixo e veja como fazer o seu cadastro. <b
                            class="text-white">É rápido, fácil e
                            gratuito.</b></p>
                    <a href="/como-acessar" class="btn btn-outline-primary botao-branco">Como Acessar</a>
                </div>
            </div>

            <div class="col-md-6">
                <img class="img-fluid" src="/assets/media/computador.png" alt="computador"  data-aos="fade-left">
            </div>
        </div>
    </div>
</section>
<footer>
    <a href="https://www.easylifecorporate.com.br">
        <img src="/assets/media/logo-efeito.png" alt="easylife-corporate">
    </a>
</footer>